/* eslint-disable react-hooks/exhaustive-deps */

import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import {
  Apartments,
  Nav,
  News,
  CallToAction,
  ParkingStorage,
} from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  BanerBackground,
  AboutSecond,
  AboutThird,
  BanerConvenients,
} from "page_components/investment/ultradom"

const Investment = ({ data, location }) => {
  const investment = "Ultradom"
  const investmentRaw = "ultradom"
  const allParkings = data.allWpParkingUltradom.nodes
  const allStorages = data.allWpStorageUltradom.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: investment,
        image: withPrefix("/ultradom-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={investment}
        parent={{ label: "Radom", url: "/mieszkania/radom" }}
        subParent={{ label: "Mieszkania", url: "/mieszkania" }}
      />
      <Hero title={investment} />
      <Nav
        title={investment}
        investment={investment}
        investmentRaw={investmentRaw}
      />
      <Apartments investment={investment} investmentRaw={investmentRaw} />
      <About title={investment} />
      <BanerBackground />
      <AboutSecond />
      <Location />
      <AboutThird />
      <BanerConvenients />
      <Gallery />
      <News investment={investmentRaw} city="Radom" />
      <CallToAction />
      <ParkingStorage
        allParkings={allParkings}
        allStorages={allStorages}
        investment={investment}
        investmentRaw={investmentRaw}
      />
      <ContactForm
        formType="sale"
        investment={investment}
        investmentRaw={investmentRaw}
      />
      {/* <Popup type="radom" /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpParkingUltradom {
      nodes {
        id
        title
        uri
        slug
        acfParking {
          parkingNumber
          status
          parkingType
          building
        }
      }
    }
    allWpStorageUltradom {
      nodes {
        id
        title
        uri
        slug
        acfStorage {
          storageNumber
          area
          status
          storageType
          building
          pietro
        }
      }
    }
  }
`

export default Investment
